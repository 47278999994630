<template lang="pug">
InputGroupControl(:label="$t('padding')" type="padding" :customProperty="customProperty")
</template>
<script>
  import InputGroupControl from './InputGroup.vue';

  export default {
    components: { InputGroupControl },
    props: {
      customProperty: {
        type: String,
        default: null,
      },
    },
  };
</script>
